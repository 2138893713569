body {
  background: #535ad0;
}

.divisor {
  border-bottom: 1px solid #d0d0d2;
  padding-bottom: 1em;
  width: '100%';
}

.divisor-mini {
  border-bottom: none;
  padding-bottom: 1em;
  width: '100%';
}

.name-item {
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 1.2em;
  background: #535ad0;
  color: white;
  padding: 6px 12px;
}
